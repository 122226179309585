<template>
  <v-container id="dashboard" fluid tag="section">
    <v-row>
      <!-- QUERY DATE-RANGE -->
      <v-col cols="12" lg="5">
        <v-row>
          <v-col>
            <DateRangePicker @update="onDateRangeUpdated" :def-dates="dates">
            </DateRangePicker>
          </v-col>
        </v-row>
      </v-col>

      <!-- SEARCH -->
      <v-col cols="12" sm="6" lg="4">
        <v-card class="my-0" style="height: 100%;">
          <v-card-text>
            <v-container>
              <v-row align="end">
                <v-col cols="12" class="pa-0">
                  <v-radio-group row v-model="form.search.searchBy">
                    <template v-slot:label>
                      <div>{{ $t("views.merchants.searchBy") }}<br /></div>
                    </template>
                    <v-radio
                      :label="$t('views.batches.refId')"
                      value="refId"
                    ></v-radio>
                    <v-radio
                      :label="$t('views.batches.description')"
                      value="description"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" class="pa-0">
                  <v-form @submit.native.prevent @submit="search">
                    <v-text-field
                      outlined
                      prepend-icon="mdi-magnify"
                      v-model="form.search.keyword"
                      placeholder="(Press ENTER to search)"
                      clearable
                    >
                    </v-text-field>
                  </v-form>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        lg="2"
        style="height: 100%;"
        class="d-flex align-start flex-column"
      >
        <v-btn x-large block color="warning" @click="openUploadDialog">
          <v-icon left>mdi-bank-transfer-in</v-icon>
          {{ $t(`views.batches.back`) }}
        </v-btn>
        <v-btn block large color="grey darken-3" @click="forceRefresh">
          <v-icon>mdi-refresh</v-icon>
          {{ $t("views.batches.refreshButton") }}
        </v-btn>
      </v-col>
      <!-- LIST OF ORDERS -->
      <v-col cols="12">
        <v-card class="mt-0">
          <v-card-title>
            <v-btn
              rounded
              depressed
              small
              color="blue"
              class="ml-1"
              :disabled="!selected.length"
              @click="exportInvoice"
            >
              <v-icon left>mdi-file-export</v-icon>
              {{ $t(`views.batches.exportInvoice`) }}
            </v-btn>
          </v-card-title>

          <v-card-text>
            <SSRTable
              ref="list-batches"
              :data-url="loadUrl"
              :data-key="'batches'"
              :schema="schema"
              :selectable="true"
              :multi-select="true"
              @select-row="onSelectRow"
              @select-all="onSelectAll"
            >
              <div slot="failed" slot-scope="{ item }">
                <!-- <span :style="{'color': item.failed ? 'red' : 'black'}"> {{ item.failed }} </span> -->
                <a
                  @click="openDetailDialog(item)"
                  :style="{
                    'pointer-events': item.batchId ? 'none' : 'auto'
                  }"
                >
                  {{ item.failed }}
                </a>
              </div>
              <div slot="returnFailed" slot-scope="{ item }">
                <a
                  @click="openRemittancesDetailDialog(item)"
                  :style="{
                    'pointer-events': item.returnedAt ? 'auto' : 'none'
                  }"
                >
                  {{ item.returnFailed }}
                </a>
              </div>
              <div slot="period" slot-scope="{ item }">
                <span>
                  {{ tsToDateString(item.fromDate) }} -
                  {{ tsToDateString(item.toDate) }}
                </span>
              </div>
              <div slot="createdAt" slot-scope="{ item }">
                <span> {{ tsToDateTimeString(item.createdAt) }} </span>
              </div>
              <div slot="batchStatus" slot-scope="{ item }">
                <span> {{ $t(`views.batches.${item.batchStatus}`) }} </span>
              </div>
              <div slot="actions" slot-scope="{ item }">
                <v-btn
                  rounded
                  depressed
                  small
                  color="blue"
                  class="ml-1"
                  :disabled="
                    item.failed > 0 ||
                      item.batchStatus !== 'created' ||
                      dialog.review.isLoading
                  "
                  @click="openReviewDialog(item)"
                >
                  <v-icon left>mdi-file-export</v-icon>
                  {{ $t(`views.batches.checkout`) }}
                </v-btn>
                <!-- <v-btn rounded depressed small color="blue" class="ml-1"
                  :disabled="item.failed ? true: false"
                  @click="exportACH(item.refId)">
                  <v-icon left>mdi-file-export</v-icon>
                  {{ $t('views.batches.exportACH') }}
                </v-btn> -->
                <!-- <v-btn rounded depressed small color="orange" class="ml-1"
                  :disabled="item.failed ? true: false"
                  @click="exportCSV(item.id)">
                  <v-icon left>mdi-file-export</v-icon>
                  下載結帳報表
                </v-btn>
                <v-btn rounded depressed small color="green" class="ml-1"
                  :disabled="!item.succeeded"
                  @click="openLedgerSummaryDialog(item)">
                  <v-icon left>mdi-archive-eye</v-icon>
                  出帳明細
                </v-btn> -->
              </div>
            </SSRTable>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Review Dialog -->
    <v-dialog v-model="dialog.review.show" width="40%" :persistent="true">
      <v-card>
        <v-card-title>
          {{ $t("views.remittances.billingPeriod") }}:
          {{ dialog.review.model.from }} - {{ dialog.review.model.to }}
          <br />
          <h4 style="color:red">
            {{ $t("views.remittances.transferNotice") }}
          </h4>
        </v-card-title>
        <v-card-text>
          <v-menu
            ref="schedule-date-picker"
            v-model="dialog.review.menu"
            offset-y
            min-width="auto"
            :close-on-content-click="false"
            :return-value.sync="dialog.review.model.schedule"
            transition="scale-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="Scheduled date to transfer"
                offset-y
                min-width="auto"
                v-model="dialog.review.model.schedule"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-model="dialog.review.model.schedule"
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="dialog.review.menu = false"
                >Cancel</v-btn
              >
              <v-btn
                text
                color="primary"
                @click="
                  $refs['schedule-date-picker'].save(
                    dialog.review.model.schedule
                  )
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="grey"
            :disabled="dialog.review.model.isLoading"
            @click="cancel"
            >Cancel</v-btn
          >
          <v-btn
            color="error"
            :disabled="dialog.review.model.isLoading"
            :loading="dialog.review.model.isLoading"
            @click="submitACH"
            >Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Error Records-->
    <v-dialog v-model="dialog.detail.show">
      <v-card>
        <v-card-title>
          ACH Error Records
        </v-card-title>
        <v-card-text>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Batch reference ID</v-list-item-title>
              <v-list-item-subtitle>{{
                dialog.detail.refId
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title
                >Batch description (transfer note)</v-list-item-title
              >
              <v-list-item-subtitle class="red--text">{{
                dialog.detail.description
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                {{ dialog.detail.succeeded }} records are successful.
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                {{ dialog.detail.failed }} records have errors.
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <span class="text-h5">Error Records</span>
          <SSRTable
            ref="upload-error-records"
            :data="dialog.detail.errorRecords"
            :schema="dialog.detail.errorRecordSchema"
          >
          </SSRTable>
        </v-card-text>
        <v-card-actions>
          <v-btn color="secondary" @click="dialog.detail.show = false">{{
            $t("views.batches.closeButton")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Ledger Summarires-->
    <v-dialog v-model="dialog.ledgerSummary.show">
      <v-card>
        <v-card-title>
          Ledger Summarires
        </v-card-title>
        <v-card-text>
          <SSRTable
            ref="ledger-summaries"
            :data-url="loadLedgerSummariesUrl"
            :data-key="'remittances'"
            :schema="ledgerSummariesSchema"
          >
            <div slot="recipient" slot-scope="{ item }">
              <span> {{ item.recipient_bank_name }} </span><br />
              <span> {{ item.recipient_bank }} </span><br />
              <span> {{ item.recipient_name }} </span><br />
              <span> {{ item.recipient_no }} </span>
            </div>

            <div slot="invoice" slot-scope="{ item }">
              <span v-if="item.invoice_type === 'b2b'">
                {{ $t("views.remittances.invoice.B2B") }}
              </span>
              <span v-else-if="item.invoice_type === 'b2c'">
                {{ $t("views.remittances.invoice.B2C") }} </span
              ><br />
              <span> {{ item.invoice_email }} </span><br />
              <span> {{ item.invoice_title }} </span><br />
              <span> {{ item.invoice_tax_id }} </span>
            </div>

            <div slot="amount" slot-scope="{ item }">
              $ {{ Number(item.amount).toLocaleString() }}
            </div>
            <div slot="service_fee" slot-scope="{ item }">
              $ {{ Number(item.service_fee).toLocaleString() }}
            </div>
            <div slot="transfer_fee" slot-scope="{ item }">
              $ {{ Number(item.transfer_fee).toLocaleString() }}
            </div>
            <!-- <div slot="transfer_amount" slot-scope="{item}">
                $ {{ Number(item.transfer_amount).toLocaleString() }}
              </div> -->
            <div slot="payable" slot-scope="{ item }">
              $ {{ Number(item.payable).toLocaleString() }}
            </div>
          </SSRTable>
        </v-card-text>
        <v-card-actions>
          <v-btn color="secondary" @click="dialog.ledgerSummary.show = false">{{
            $t("views.batches.closeButton")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- 回檔 Summarires-->
    <v-dialog v-model="dialog.remittances.show">
      <v-card>
        <v-card-title>
          Remittances Error Records
        </v-card-title>
        <v-card-text>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Batch reference ID</v-list-item-title>
              <v-list-item-subtitle>{{
                dialog.remittances.refId
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title
                >Batch description (transfer note)</v-list-item-title
              >
              <v-list-item-subtitle class="red--text">{{
                dialog.remittances.description
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                {{ dialog.remittances.succeeded }} records are successful.
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                {{ dialog.remittances.failed }} records have errors.
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <span class="text-h5">Error Records</span>
          <SSRTable
            ref="upload-error-records"
            :data="dialog.remittances.errorRecords"
            :schema="dialog.remittances.errorRecordSchema"
          >
            <div slot="AMT" slot-scope="{ item }">
              {{ Number(item.AMT).toLocaleString() }}
            </div>
          </SSRTable>
        </v-card-text>
        <v-card-actions>
          <v-btn color="secondary" @click="dialog.remittances.show = false">{{
            $t("views.batches.closeButton")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- 回檔-->
    <v-dialog v-model="dialog.upload.show" max-width="700">
      <AchResultUploader @uploaded="onUploaded" @cancel="onUploadCancel" />
    </v-dialog>
  </v-container>
</template>

<script>
import SSRTable from "@/components/SSRTable";
import DateRangePicker from "@/components/DateRangePicker";
import AchResultUploader from "@/views/dashboard/ach-service/AchResultUploader";

import axios from "axios";
import moment from "moment";

export default {
  components: {
    SSRTable,
    DateRangePicker,
    AchResultUploader
    // ScopeSelector,
  },

  mounted() {},

  methods: {
    onDateRangeUpdated(dates) {
      this.dates = dates;
    },
    search() {
      if (this.form.search.keyword) {
        this.form.search.queryString = `&search=${
          this.form.search.searchBy
        }:${encodeURIComponent(this.form.search.keyword)}`;
      } else {
        this.form.search.queryString = "";
      }
      //NOTE: search keyword turns null when 'cleared' by user??
    },
    forceRefresh() {
      this.$refs["list-batches"].loadData();
    },

    tsToDateTimeString(ts) {
      if (ts) {
        return moment.unix(ts).format("YYYY/MM/DD HH:mm:ss");
      }
      return "";
    },
    tsToDateString(ts) {
      if (ts) {
        return moment.unix(ts).format("YYYY/MM/DD");
      }
      return "";
    },
    exportACH(refId) {
      if (
        !confirm(
          "You are about to download ACH transfer file. Sure to continue?"
        )
      )
        return;

      if (!refId) {
        console.error(`[Batches] No ref ID?!!`);
        return;
      }

      window.open(
        `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/ach/preview/${refId}`
      );
    },

    exportCSV(batchId) {
      if (!confirm("You are about to download the CSV file. Sure to continue?"))
        return;

      let result = axios
        .get(
          `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/ledger-batches/${batchId}/download`
        )
        .then(result => {
          let fileurl = result?.data?.fileurl;
          if (fileurl) {
            window.open(fileurl);
          } else {
            this.$notify({
              text: "No CSV file can be downloaded.",
              type: "warning"
            });
          }
        });
    },

    openDetailDialog(item) {
      (this.dialog.detail.refId = item.refId),
        (this.dialog.detail.description = item.description);
      this.dialog.detail.succeeded = item.succeeded;
      this.dialog.detail.failed = item.failed;
      this.dialog.detail.errorRecords = item.previewResult.errorRecords;
      this.dialog.detail.show = true;
    },

    openRemittancesDetailDialog(item) {
      // "errRecords":[
      // {
      // "AMT":"0000013726",
      // "CID":"85111436",
      // "CNO":" ",
      // "PID":"54705238",
      // "SEQ":"00329378",
      // "SID":" ",
      // "CFEE":"00000 ",
      // "MEMO":" LJTQS82U",
      // "NOTE":" 1LJd7kjE4",
      // "PSEQ":"00000001",
      // "SCHD":"B",
      // "TXID":"405",
      // "TYPE":"R",
      // "NOTEB":" ",
      // "PBANK":"0210018",
      // "PCLNO":"0000001300123111",
      // "PDATE":"01120214",
      // "PSCHD":"B",
      // "RBANK":"0122009",
      // "RCLNO":"0082110000212643",
      // "RCODE":"04",
      // "FILLER":" ",
      // "TXTYPE":"SC"
      // }];
      (this.dialog.remittances.refId = item.refId),
        (this.dialog.remittances.description = item.description);
      this.dialog.remittances.succeeded = item.returnSucceeded;
      this.dialog.remittances.failed = item.returnFailed;
      this.dialog.remittances.errorRecords =
        item.returnResult?.r01?.result?.errRecords || [];
      this.dialog.remittances.show = true;
    },

    openLedgerSummaryDialog(item) {
      this.dialog.ledgerSummary.id = item.id;
      this.dialog.ledgerSummary.refId = item.refId;
      this.dialog.ledgerSummary.show = true;
    },

    openReviewDialog(item) {
      let from = moment.unix(item.fromDate).format("YYYYMMDD");
      let to = moment.unix(item.toDate).format("YYYYMMDD");
      this.dialog.review.model.from = from;
      this.dialog.review.model.to = to;
      this.dialog.review.model.id = item.id;
      this.dialog.review.show = true;
    },

    submitACH() {
      let from = moment(this.dialog.review.model.from).unix();
      let to = moment(this.dialog.review.model.to).unix();
      let schedule = moment(this.dialog.review.model.schedule).format(
        "YYYYMMDD"
      );
      let batchId = this.dialog.review.model.id;
      this.dialog.review.isLoading = true;
      axios
        .post(
          `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/ledger-batches/${batchId}/submit`,
          {
            schedule,
            from,
            to,
            version: "v10",
            acquirer: "fubon",
            reviewer: this.user.username
          }
        )
        .then(response => {
          console.warn(`[Submit] succeeded`, response.data);
          this.dialog.review.show = false;
          // // Show a dialog to inform about upload result.
          // this.dialog.upload.refId = response.data['refId'];
          // this.dialog.upload.description = response.data['description'];
          // this.dialog.upload.uploadResult = response.data['result'];
          // this.dialog.upload.errorRecords = response.data['errorRecords'];
          // this.dialog.upload.show = true;
          // force refresh
          this.forceRefresh();
          this.$notify({
            text: `Request processing, please check if you have received the file.`,
            type: "success"
          });
        })
        .catch(err => {
          console.error(`[Submit] failed`, err);
          this.$notify({
            text: `ACH transfer request upload failed: ${err.response.data.message}`,
            type: "error"
          });
        });
    },

    cancel() {
      this.dialog.review.show = false;
      this.dialog.review.model.from = null;
      this.dialog.review.model.to = null;
      this.dialog.review.model.schedule = moment()
        .add(1, "days")
        .format("YYYY-MM-DD");
    },

    openUploadDialog() {
      this.dialog.upload.show = true;
    },
    onUploaded() {
      this.forceRefresh();
      this.dialog.upload.show = false;
    },
    onUploadCancel() {
      this.dialog.upload.show = false;
    },
    onSelectRow(item, value) {
      console.log("onSelectRow:", item, value);
      if (value) {
        if (item.refId) this.selected.push(item);
      } else {
        const _index = this.selected.findIndex(i => i.id === item.id);
        console.log(_index);
        this.selected.splice(_index, 1);
      }
    },
    onSelectAll(data) {
      console.log("onSelectAll");
      if (data.value) {
        let achs = data.items
        achs.forEach(ach => {
          if (ach.refId) this.selected.push(ach);
        });
      } else {
        this.selected = []
      }
    },
    exportInvoice() {

      if (!this.selected.length) {
        this.$notify({
          text: "No batch is selected.",
          type: "warning"
        });
        return;
      }
      if (
        !confirm(
          "You are about to download the CSV file for invoices. Sure to continue?"
        )
      )
        return;

      const batchIds = this.selected.map(item => item.refId);
      const _batchIds = batchIds.toString();
      let url = `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/ledger-batches/download-sums?batchIds=${_batchIds}`;
      window.open(url);
      // let result = axios
      //   .get(
      //     `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/ledger-batches/download-sums?batchIds=${_batchIds}`
      //   )
      //   .then(result => {
      //     let fileurl = result?.data?.fileurl;
      //     if (fileurl) {
      //       window.open(fileurl);
      //     } else {
      //       this.$notify({
      //         text: "No CSV file can be downloaded.",
      //         type: "warning"
      //       });
      //     }
      //   });
    }
  },

  data() {
    return {
      dates: [
        moment()
          .startOf("isoWeek")
          .format("YYYY-MM-DD"),
        moment()
          .endOf("isoWeek")
          .format("YYYY-MM-DD")
      ],
      scope: {},

      form: {
        search: {
          searchBy: "",
          keyword: "",
          queryString: ""
        }
      },
      dialog: {
        upload: {
          show: false
        },
        review: {
          isLoading: false,
          show: false,
          menu: false,
          model: {
            from: null,
            to: null,
            schedule: moment()
              .add(1, "days")
              .format("YYYY-MM-DD"),
            id: null
          }
        },
        detail: {
          show: false,
          refId: undefined,
          description: "",
          succeeded: 0,
          failed: 0,
          errorRecords: [],
          errorRecordSchema: [
            // { value: 'merchantId', text: 'Merchant' },
            { value: "merchant", text: "Merchant" },
            { value: "recipient_bank", text: "Rcpnt Bank" },
            { value: "recipient_id", text: "Rcpnt" },
            { value: "recipient_no", text: "Rcpnt Account" },
            { value: "amount", text: "$", align: "right" },
            { value: "note", text: "Note" },
            { value: "errMsg", text: "Error" }
          ]
        },
        ledgerSummary: {
          show: false,
          id: undefined,
          refId: undefined
        },
        remittances: {
          show: false,
          refId: undefined,
          description: "",
          succeeded: 0,
          failed: 0,
          errorRecords: [],
          errorRecordSchema: [
            { value: "NOTE", text: "Merchant" },
            { value: "RBANK", text: "Rcpnt Bank" },
            { value: "PID", text: "Rcpnt" },
            { value: "RCLNO", text: "Rcpnt Account" },
            { value: "AMT", text: "$", align: "right" },
            { value: "RCODE", text: "Error Code" }
          ]
        }
      },
      selected: [],
    };
  },

  computed: {
    user() {
      return this.$store.getters.user;
    },
    loadUrl() {
      let from = moment(this.dates[0]).startOf("day");
      let to = moment(this.dates[1]).endOf("day");
      return (
        `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/ledger-batches` +
        `?from=${from.unix()}&to=${to.unix()}` +
        `${this.form.search.queryString}`
      );
    },

    schema() {
      return [
        { value: "id", text: "#", sortable: false },
        { value: "filename", text: "ACH來源檔", sortable: false },
        {
          value: "refId",
          text: this.$t("views.batches.refId"),
          sortable: false
        },
        {
          value: "description",
          text: this.$t("views.batches.description"),
          sortable: false
        },
        {
          value: "period",
          text: this.$t("views.batches.period"),
          sortable: false
        },
        {
          value: "succeeded",
          text: this.$t("views.batches.succeeded"),
          sortable: false,
          align: "end",
          width: 128
        },
        {
          value: "failed",
          text: this.$t("views.batches.failed"),
          sortable: false,
          align: "end",
          width: 128
        },
        {
          value: "returnSucceeded",
          text: this.$t("views.batches.returnSucceeded"),
          sortable: false,
          align: "end",
          width: 128
        },
        {
          value: "returnFailed",
          text: this.$t("views.batches.returnFailed"),
          sortable: false,
          align: "end",
          width: 128
        },
        {
          value: "batchStatus",
          text: this.$t("views.batches.status"),
          sortable: false
        },
        {
          value: "reviewer",
          text: this.$t("views.batches.reviewer"),
          sortable: false
        },
        {
          value: "createdAt",
          text: this.$t("views.batches.createdAt"),
          sortable: false
        },
        {
          value: "actions",
          text: this.$t("views.batches.actions"),
          sortable: false
        }
      ];
    },

    loadLedgerSummariesUrl() {
      if (!this.dialog.ledgerSummary.id) return;
      return (
        `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/ledger-batches/${this.dialog.ledgerSummary.id}` +
        `?refId=${this.dialog.ledgerSummary.refId}`
      );
    },

    ledgerSummariesSchema() {
      return [
        {
          value: "merchant_id",
          text: this.$t("views.remittances.merchantId"),
          sortable: false
        },
        {
          value: "shop_name",
          text: this.$t("views.remittances.shopName"),
          sortable: false
        },
        {
          value: "amount",
          text: this.$t("views.remittances.amount"),
          sortable: false,
          align: "end",
          width: 128
        },
        {
          value: "service_fee",
          text: this.$t("views.remittances.serviceFee"),
          sortable: false,
          align: "end",
          width: 128
        },
        {
          value: "transfer_fee",
          text: this.$t("views.remittances.transferFee"),
          sortable: false,
          align: "end",
          width: 128
        },
        {
          value: "surcharge",
          text: this.$t("views.remittances.surcharge"),
          sortable: false,
          align: "end",
          width: 128
        },
        {
          value: "payable",
          text: this.$t("views.remittances.transferAmount"),
          sortable: false,
          align: "end",
          width: 128
        },
        {
          value: "recipient",
          text: this.$t("views.remittances.recipient"),
          sortable: false
        },
        {
          value: "invoice",
          text: this.$t("views.remittances.invoice.fieldTitle"),
          sortable: false
        }
        // { value: 'actions', text: this.$t('views.cardpay.orders.actions'), sortable: false },
      ];
    }
  }
};
</script>
