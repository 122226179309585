<template>
  <v-card outlined class="pa-6">
    <v-card-title>
      Upload your ACH result file (.txt only)
    </v-card-title>

    <v-card-text>

      <v-form>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-radio-group row v-model="form.upload.model.acquirer">
                <template v-slot:label>
                  <div>收單行<br></div>
                </template>
                <v-radio label="台新銀行" value="taishin"></v-radio>
                <v-radio label="富邦銀行" value="fubon"></v-radio>             
              </v-radio-group>
            </v-col>
            <v-col cols="12" class="d-flex justify-space-between">
              <v-file-input accept="text/plain"
                label="Upload TXT file" v-model="form.upload.model.file" >
              </v-file-input>
            </v-col>

          </v-row>
        </v-container>
      </v-form>

    </v-card-text>

    <v-card-actions class="d-flex justify-end">
      <v-btn depressed large color="gray" :disabled="form.upload.isUploading" @click="cancel">
        Cancel
      </v-btn>
      <v-btn depressed large color="error" :disabled="form.upload.isUploading" :loading="form.upload.isUploading" @click="upload">
        <v-icon left>mdi-file-upload-outline</v-icon>
        Upload
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import SSRTable from '@/components/SSRTable';

  import axios from 'axios';
  // import moment from 'moment';


  export default {
    components: {
      SSRTable
    },

    mounted() {

    },

    methods: {
      
      upload() {
        if (!this.form.upload.model.file) {
          alert(`You must upload a txt file.`);
          return;
        }
        this.form.upload.isUploading = true;
        let version = this.form.upload.model.acquirer === 'fubon' ? 'v10': null;
        let form = new FormData();
        form.append('achResults', this.form.upload.model.file);
        if (version) {
          form.append('version', version);
        }
        axios( {
          method: 'post',
          url: `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/remittances/result`,
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: form
        })
        .then(response => {
          console.warn(`[TransferBack] Upload succeeded`, response.data);
          this.$notify({
            text: `Request processing. The result will be updated after processing.`,
            type: 'success'
          });
          this.$emit('uploaded');
          this.form.upload.isUploading = false;
          this.form.upload.model.file = null;
        })
        .catch(err => {
          console.error(`[TransferBack] Upload failed`, err);
          this.$notify({
            text: `ACH result request upload failed: ${err.message}`,
            type: 'error'
          });
          this.form.upload.isUploading = false;
        });
      },

      cancel() {
        this.$emit('cancel');
      }
    },

    data() {
      return {
        
        records: [],
        
        form: {
          upload: {
            isUploading: false,
            model: {
              file: undefined,
              acquirer: 'fubon'
            }
          }
        },
        
      };
    },

  }
</script>
